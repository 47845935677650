import React from 'react';

import Spacer from "../components/spacer"
import Layout from '../components/layout';
import SEO from "../components/seo"
import { FaFacebookSquare } from 'react-icons/fa';

import { PageHeading, P, TextBlockHeading } from '../components/typography';

const Covid = () => {
  return (
    <Layout>
        <SEO title="Covid Response" />
        <div className="flex justify-center" >
          <div className="text-center px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-5xl" >
            <Spacer />
            <P margin="mb-6">
              Our primary goal is ensuring the safety of our patients and 
              staff while maintaining smooth operation 
              and complying with local guidelines.
            </P>
                
            <P margin="mb-6">Below we have detailed our updated <strong>Visitors' Policy</strong>, and our own 
              <strong> Safety Policy</strong>.
            </P>
            <P margin="mb-6">We will keep this page updated with any updates. However, we 
              encourage you to follow or bookmark our  
              <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/Multnomah-Medical-Clinic-100448531665199">
                <FaFacebookSquare style={{ fill: 'rgb(59, 134, 197) '}} className="inline mx-1" />
                Facebook Page
              </a> for the latest news and a direct way to message us regarding any questions or concerns 
              you may have.
            </P>
            <TextBlockHeading>Updated Visitors' Policy</TextBlockHeading>
            <P margin="mt-2 mb-4">
              Visits will mostly be conducted as usual, except we ask
              you to take a few additional precautions:
            </P>
            <ul className="covid-list" >
              <li><strong>
                1. Feeling sick? Cancel.</strong> If you are showing first signs of symptoms, or 
                if you have had contact with someone who has a confirmed case of COVID-19, please 
                call us to cancel your appointment. Treatment will be allowed to resume normally 
                after you have been cleared by a medical doctor.
              </li>
              <li><strong>
                2. A face covering is required.</strong> Masks will be provided based on availability,
                but our supplies may be limited. If you don't have a face covering, contact us 
                in advance to confirm that we will be able provide one.
              </li>
              <li>
                <strong>3. Sanitize! </strong> We will provide ways for you to sanitize and wash your 
                hands when you come in for your appointment.
              </li>
              <li>
                <strong>4. Social distancing.</strong> Maintain a reasonable distance from 
                other patients and our staff while in the office. 
              </li>
            </ul>
            <TextBlockHeading>Our Safety Policy</TextBlockHeading>
            <P margin="mt-2 mb-4">To ensure safety and to remain compliant, we will be 
              following all CDC and local guidelines. Here are some of the actions we will be taking:
              </P>
            <ul className="covid-list">
              <li>
                <strong>1. All staff will have adequate facial coverings. </strong>
                  Expect to see us in masks.
              </li>
              <li>
                <strong>2. All staff will have temperature screenings before their shift. </strong>
                  Any staff member
                who is showing first signs of symptoms or who has had exposure to the virus will not be 
                scheduled until they have completely recovered and have been cleared to return to work
                by a doctor.
              </li>
              <li>
                <strong>3. Our janitorial staff will conduct daily cleanings </strong>
                    with Oxivir, one of the best disinfectants
                for getting rid of the virus. Additionally, there will be hourly sanitization of all of the 
                surfaces within our office.
              </li>
              <li>
                <strong>4. Smarter scheduling. </strong> We will be scheduling appointments so that there is minimal overlap between patients
                to reduce the number of people who will be in our office at any given time. This has the added benefit of reducing wait times 
                while keeping you safe.
              </li>
            </ul>
            <TextBlockHeading>Additional Safety Resources</TextBlockHeading>
            <P margin="mt-2">
              <a href="https://govstatus.egov.com/OR-OHA-COVID-19">
                <u>Latest official COVID-19 Updates from Oregon Health Authority</u>
              </a>
            </P>
            <P>
              <a href="https://www.cdc.gov/coronavirus/2019-ncov/index.html">
                <u>Latest official COVID-19 Updates from the CDC</u>
              </a>
            </P>
          </div>
        </div>
    </Layout>
  )
}

export default Covid